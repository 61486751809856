import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Card, CardContent, Typography, Snackbar, Box, Avatar, Autocomplete, TextField, MenuItem, FormControl, Select, InputLabel  } from '@mui/material';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';
import { CircularProgress } from '@mui/material'; // Voor laadindicator
import Button from '@mui/material/Button';


import dayjs from 'dayjs';
import updateLocale from 'dayjs/plugin/updateLocale';
dayjs.extend(updateLocale);

dayjs.updateLocale('be', {
  weekStart: 1, // Week starts on Monday (1)
});

// Update locale to start the week from Monday
dayjs.extend(updateLocale);
dayjs.updateLocale('be', {
  weekStart: 1, // Week starts on Monday (1)
});

const shouldDisableWeekend = (date) => {
  const day = date.day();
  return day === 0 || day === 6; // 0 = Sunday, 6 = Saturday
};

const AanwezighedenPage = ({ apiBaseUrl }) => {
  const [leerlingen, setLeerlingen] = useState([]);
  const [filteredLeerlingen, setFilteredLeerlingen] = useState([]);
  const [loading, setLoading] = useState(false); // Voeg loading state toe

  // State for filters
  const [selectedClasses, setSelectedClasses] = useState([]);
  const [selectedCoaches, setSelectedCoaches] = useState([]);
  const [selectedDate, setSelectedDate] = useState(dayjs());
  const [selectedLessonHour, setSelectedLessonHour] = useState(null);

  // Use useEffect to check the time when the component mounts
  useEffect(() => {
    const currentHour = new Date().getHours();
    
    // If it's after 13:00 (1:00 PM), set the default value to 5, else set to 1
    const initialLessonHour = (currentHour >= 13 && dayjs().day() !== 3) ? 5 : 1;
    setSelectedLessonHour(initialLessonHour);  // Set the initial lesson hour
  }, []); // The empty dependency array ensures this runs only on component mount

  const [lessonHours, setLessonHours] = useState([1, 5]);
  const [selectedCourseGroup, setSelectedCourseGroup] = useState('');

  const [successMessage, setSuccessMessage] = useState('');

  useEffect(() => {
    const fetchLeerlingen = async () => {
      // Only call API once selectedLessonHour is not null (set by the above useEffect)
      if (selectedLessonHour !== null) {setLoading(true); // Zet loading op true bij het starten van de API-aanroep
        const formattedDate = selectedDate.format('YYYY-MM-DD');
        const lessonHour = selectedLessonHour || 1;

        try {
          const response = await axios.get(`${apiBaseUrl}/aanwezigheden/${formattedDate}/${lessonHour}`);
          if (response.data.status === 201) {
            resetSelections();
            setLeerlingen(response.data.data);
            setFilteredLeerlingen(response.data.data);
          } else {
            console.error("Geen geldige data ontvangen van de API");
          }
        } catch (error) {
          console.error("Er is een fout opgetreden tijdens het ophalen van de data:", error);
        } finally {
          setLoading(false); // Zet loading op false zodra de API-aanroep klaar is
        }
      }
    };

    fetchLeerlingen();
  }, [selectedDate, selectedLessonHour]);// Deze effect wordt opnieuw uitgevoerd wanneer de datum of lesuur wijzigt
  //Als succesMessage nog anders aangepast wordt dan door handleConfirm dan moet je dit wel anders regelen, want zo zorgt handleconfirm dat de bewaarde info opnieuw ingeladen wordt

  // Filter logic based on selected filters
  useEffect(() => {
    let filtered = leerlingen;

    // Apply filters to leerlingen list
    if (selectedClasses.length > 0) {
      filtered = filtered.filter(leerling => selectedClasses.includes(leerling.klas));
    } 

    if (selectedCourseGroup) {
      filtered = filtered.filter(leerling => leerling.vak_groep === selectedCourseGroup);
    }

    if (selectedCoaches.length > 0) {
      filtered = filtered.filter(leerling => selectedCoaches.includes(leerling.acro));
    }

    setFilteredLeerlingen(filtered);
  }, [selectedClasses, selectedCourseGroup, selectedCoaches, leerlingen]);
  
  // Reset filters
  const resetSelections = () => {
    setSelectedClasses([]);      // Zet selectedClasses terug naar een lege array
    setSelectedCoaches([]);      // Zet selectedCoaches terug naar een lege array
    setSelectedCourseGroup('');  // Zet selectedCourseGroup terug naar een lege string
  };
    
  // Extract unique classes for filter options
  const classOptions = Array.from(new Set(leerlingen.map(leerling => leerling.klas))).sort();
  // Extract uniqye coaches for filter options
  const coachOptions = Array.from(new Set(leerlingen.map(leerling => leerling.acro).filter(Boolean))).sort();
  
  //const courseGroups = Array.from(new Set(leerlingen.map(leerling => leerling.vak_groep).filter(Boolean))).sort();
  const courseGroups = Array.from(new Set(
    leerlingen.map(leerling => 
      JSON.stringify({ vak_groep: leerling.vak_groep, vak_acro: leerling.vak_acro })
    ).filter(Boolean))
  ).map(group => JSON.parse(group)).sort((a, b) => a.vak_groep.localeCompare(b.vak_groep));
  
  // Function to update lesson hours based on the selected date
  useEffect(() => {
    const dayOfWeek = selectedDate.day(); // Get day of the week, 0 (Sunday) - 6 (Saturday)
    
    if (dayOfWeek === 3) { // Wednesday (day 3)
      setLessonHours([1]); // Only 1st lesson hour on Wednesday
      setSelectedLessonHour(''); // Reset selected lesson hour when switching to Wednesday
    } else {
      setLessonHours([1, 5]); // Normal hours for other days
    }
  }, [selectedDate]);

  const handleConfirm = () => {
    const payload = filteredLeerlingen.map(leerling => ({
      leerling_id: leerling.leerling_id,
      status: leerling.aanwezigheid_status,
      lesdatum: selectedDate.format('YYYY-MM-DD'),
      lesuur: selectedLessonHour || 1,  // Default to 1 if no lesson hour is selected
      email_school: "nouser"           // Add user's email if necessary
    }));
  
    axios.post(`${apiBaseUrl}/aanwezigheden/batch_registreren`, { leerlingen: payload })
      .then(response => {
        console.log('Alle leerlingen succesvol bijgewerkt:', response.data);
        if (response.status === 200) {
          setSuccessMessage('Aanwezigheden succesvol bijgewerkt!');
          // Updating the state as mentioned above
           // Update state: set 'aanwezigheid_status' to 'A' only for filtered students with null status
        setLeerlingen(prevLeerlingen =>
          prevLeerlingen.map(leerling => {
            const isFiltered = filteredLeerlingen.some(
              filtered => filtered.leerling_id === leerling.leerling_id
            );
            return isFiltered && leerling.aanwezigheid_status === null
              ? { ...leerling, aanwezigheid_status: 'A' }
              : leerling;
          })
        )}
      })
      .catch(error => {
        console.error('Fout bij het bijwerken van de leerlingen:', error);
      });
  };

  // Functie om de status te wijzigen
  const handleStatusChange = (leerlingId, newStatus) => {
    const formattedDate = selectedDate.format('YYYY-MM-DD');
    const lessonHour = selectedLessonHour || 1;

    setLeerlingen(prevLeerlingen =>
      prevLeerlingen.map(leerling =>
        leerling.leerling_id === leerlingId ? { ...leerling, aanwezigheid_status: newStatus } : leerling
      )
    );

    // API call om status op te slaan
    //registreer($date, $lesuur, $leerling_id, $afwezigheidstatus, $email_school)
    axios.post(`${apiBaseUrl}/aanwezigheden/${formattedDate}/${lessonHour}/${leerlingId}/${newStatus}/${encodeURIComponent("nouser")}`)
    .then(response => {
      console.log('Status succesvol bijgewerkt:', response.data);
    })
    .catch(error => {
      console.error('Fout bij het bijwerken van de status:', error);
    });
  };

  // Status kleuren
  const getStatusColor = (status) => {
    switch (status) {
      case 'A': //Aanwezig
        return 'lightgreen';
      case 'AF': //Afwezig
        return 'LightCoral';
      case 'LZ': //Te laat ZR
        return 'Gold';
      case 'LM': //Te laat MR
        return 'Gold';
      case 'EZ': //Extreem te laat zonder reden
        return 'orange';
      case 'EM': //Extreem te laat met reden
        return 'orange';
      default:
        return 'white';
    }
  };

  return (
    <div> 
      {/* Filter Form */}
      
      <div style={{ display: 'flex', flexWrap: 'wrap', gap: '16px', marginBottom: '12px', width:'100%' }}>
        
        {/* Date Picker */}
          <LocalizationProvider style={{width: '70px'}} dateAdapter={AdapterDayjs} adapterLocale="be">
            <DatePicker
              label="Selecteer datum"
              value={selectedDate}
              format="DD/MM/YYYY" // Ensure this format is used
              onChange={(newDate) => setSelectedDate(newDate)}
              shouldDisableDate={shouldDisableWeekend}  // Disable weekends
              renderInput={(params) => <TextField {...params} variant="outlined" />}
            />
          </LocalizationProvider>

        {/* Lesson Hour Filter */}
          <FormControl style={{width: '100px'}} variant="outlined">
            <InputLabel>Lesuur</InputLabel>
            <Select
              value={selectedLessonHour || 1}
              onChange={(e) => setSelectedLessonHour(e.target.value)}
              label="Lesuur"
            >
              {lessonHours.map((hour) => (
                <MenuItem key={hour} value={hour}>{hour}</MenuItem>
              ))}
            </Select>
          </FormControl>

          {(selectedClasses.length > 0 || selectedCourseGroup || selectedCoaches.length > 0) && (
            <Button
              variant="contained"
              color="primary"
              onClick={handleConfirm}
              sx={{ cursor: 'pointer', height: '55px' }} // Ensures cursor changes to pointer
            >
              Bevestigen
            </Button>
          )}
        
          {/* Class Filter */}
            <Autocomplete style={{width: '250px'}}
              multiple
              options={classOptions}
              value={selectedClasses}
              onChange={(event, newValue) => setSelectedClasses(newValue)}
              renderInput={(params) => (
                <TextField {...params} label="Klas" variant="outlined" />
              )}
            />

          {/* Coach Filter */}
            <Autocomplete style={{width: '250px'}}
              multiple
              options={coachOptions}
              value={selectedCoaches}
              onChange={(event, newValue) => setSelectedCoaches(newValue)}
              renderInput={(params) => (
                <TextField {...params} label="Coach" variant="outlined" />
              )}
            />

          {/* Course Filter */}
            <FormControl style={{width: '140px', float:'right'}} variant="outlined">
              <InputLabel>Vakgroep</InputLabel>
              <Select
                value={selectedCourseGroup}
                onChange={(e) => setSelectedCourseGroup(e.target.value)}
                label="Vakgroep"
              >
                <MenuItem value="">Alle</MenuItem>
                {courseGroups.map((courseGroup) => (
                  <MenuItem 
                    key={courseGroup.vak_groep} 
                    value={courseGroup.vak_groep} // Use vak_groep as value for correct comparison
                  >
                    {`${courseGroup.vak_groep} (${courseGroup.vak_acro})`}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          
          {/* Snackbar for success message */}
          <Snackbar
            open={!!successMessage}
            autoHideDuration={3000}
            message={successMessage}
            onClose={() => setSuccessMessage('')}
          />
      </div>


      {/* Student Cards */}
      {/* Laadindicator weergeven als de data wordt opgehaald */}
      {loading ? (
        <CircularProgress /> // Toont een spinner tijdens het laden
      ) : (
        <div style={{ display: 'flex', flexWrap: 'wrap', gap: '16px' }}>
          {filteredLeerlingen.length > 0 ? (
            filteredLeerlingen.map((leerling) => (
              
              <Card key={leerling.leerling_id}
              style={{ minWidth: '330px', flex: '1 1 auto', maxWidth: '330px', padding: '5px' }}>
          <CardContent sx={{
                padding: '5px !important' // Override the default padding for CardContent
              }}
            >
            <Box display="flex" alignItems="center">
              {/* Avatar */}
              <Avatar
                alt={leerling.voornaam}
                src={leerling.photoUrl || '/path/to/default/photo.jpg'} // Replace with actual photo URL or default
                style={{ width: 69, height: 69, marginRight: '16px' }} // Adjust margin for spacing
              />
        
              {/* Details */}
              <Box>
                <Typography variant="text.primary" component="div">
                  {leerling.naam} {leerling.voornaam}
                </Typography>
                <Typography color="text.secondary" variant="body2">
                  {leerling.klas} - {leerling.acro} - ({leerling.vak}) 
                </Typography>
        
                {/* Status Dropdown */}
                <Box mt={1.1} display="flex" alignItems="center">
                  <Select
                    value={leerling.aanwezigheid_status || "A"} // Default to "Aanwezig" if no status
                    onChange={(e) => handleStatusChange(leerling.leerling_id, e.target.value)}
                    sx={{
                      backgroundColor: getStatusColor(leerling.aanwezigheid_status), // Dynamic background color
                      color: 'black',
                      borderRadius: '4px',
                      height: 35,
                      width: 180,
                    }}
                  >
                    <MenuItem value="A">Aanwezig</MenuItem>
                    <MenuItem value="AF">Afwezig</MenuItem>
                    <MenuItem value="LZ">Te laat ZR</MenuItem>
                    <MenuItem value="LM">Te laat MR</MenuItem>
                    <MenuItem value="EZ">Extreem TL ZR</MenuItem>
                    <MenuItem value="EM">Extreem TL MR</MenuItem>
                  </Select>
                </Box>
              </Box>
            </Box>
          </CardContent>
        </Card>
        
              
            ))
          ) : (
            <Typography variant="h6">Geen leerlingen gevonden</Typography>
          )}
        </div>
      )}
      <div>{filteredLeerlingen.length}</div>
  </div>  
  )};

export default AanwezighedenPage;