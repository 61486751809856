import React, { useEffect, useState } from 'react';
import { DataGrid } from '@mui/x-data-grid';
import axios from 'axios';

import { Modal, TextField, Button } from '@mui/material';

const ChromebookForm = ({ rowData, onClose, setChromebooks }) => {
    const [formData, setFormData] = useState(rowData || {});
    const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;

    useEffect(() => {
        if (rowData) {
          setFormData(rowData); // Populate the form when rowData changes
        }
      }, [rowData]);
  
    const handleInputChange = (e) => {
      const { name, value } = e.target;
      setFormData({ ...formData, [name]: value });
    };
  
    const handleSubmit = () => {
      axios.put(`${apiBaseUrl}/chromebooks/${formData.serienummer}`, formData)
        .then(response => {
          setChromebooks(prevChromebooks =>
            prevChromebooks.map(chromebook =>
              chromebook.serienummer === formData.serienummer ? formData : chromebook
            )
          );
          onClose();
        })
        .catch(error => {
          console.error('Error updating chromebook data', error);
        });
    };
  
    return (
      <Modal open={true} onClose={onClose}>
        <div style={{ padding: '20px', backgroundColor: 'white', margin: 'auto', marginTop: '10%' }}>
          <h2>Edit Chromebook</h2>
          <TextField
            name="schoolID"
            label="School ID"
            value={formData.schoolID}
            onChange={handleInputChange}
            fullWidth
          />
          <TextField
            name="vloer"
            label="Vloer"
            value={formData.vloer}
            onChange={handleInputChange}
            fullWidth
          />
          <TextField
            name="acro"
            label="Acro"
            value={formData.acro}
            onChange={handleInputChange}
            fullWidth
          />
          <TextField
            name="beheer_bereik"
            label="Beheer Bereik"
            value={formData.beheer_bereik}
            onChange={handleInputChange}
            fullWidth
          />
          <Button onClick={handleSubmit} color="primary">Save</Button>
          <Button onClick={onClose} color="secondary">Cancel</Button>
        </div>
      </Modal>
    );
  };

const ChromebooksPage = ({ apiBaseUrl }) => {
  const [chromebooks, setChromebooks] = useState([]);
  const [selectedRow, setSelectedRow] = useState(null); // For the pop-up
  const [openForm, setOpenForm] = useState(false)

  // Fetch the data from API
  useEffect(() => {
    axios.get(`${apiBaseUrl}/chromebooks`)
      .then(response => {
        if (response.data.status === 201) {
            setChromebooks(response.data.data);
          } else {
            console.error("Geen geldige data ontvangen van de API");
          }
      })
      .catch(error => {
        console.error('Error fetching chromebooks data', error);
      });
  }, []);

  // Define columns
  const columns = [
    { field: 'serienummer', headerName: 'Serienummer', width: 250 },
    { field: 'schoolID', headerName: 'School ID', width: 80 },
    { field: 'naam', headerName: 'Leerling', width: 230 },
    { field: 'vloer', headerName: 'Vloer', width: 80 },
    { field: 'acro', headerName: 'Acro', width: 150 },
    { field: 'beheer_bereik', headerName: 'Range', width: 70 },
  ];

  const handleRowClick = (params) => {
    setSelectedRow(params.row);
    setOpenForm(true);
  };

  const handleFormClose = (updatedData) => {
    setOpenForm(false);
    if (updatedData) {
      setChromebooks(prevData =>
        prevData.map(row => (row.serienummer === updatedData.serienummer ? updatedData : row))
      );
    }
  };

  return (
    <div style={{width: '100%' }}>
      <DataGrid
        rows={chromebooks}
        columns={columns}
        getRowId={(row) => row.serienummer}
        pageSize={25}
        rowsPerPageOptions={[10,25,50,100]}
        pagination
        paginationMode="client"  // Ensure pagination is on the client-side
        autoHeight // This will automatically adjust the height based on the number of rows
        onRowClick={(params) => handleRowClick(params.row)}
      />
      {openForm && (
        <ChromebookForm
          rowData={selectedRow}
          onClose={handleFormClose}
        />
      )}
    </div>
  );
};

export default ChromebooksPage;
