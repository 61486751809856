import React, { useState } from 'react';
import { BrowserRouter as Router, Route, Routes, useLocation } from 'react-router-dom';
import { useMediaQuery, Box, CssBaseline, Toolbar } from '@mui/material';
import Navbar from './components/Navbar';
import Sidebar from './components/Sidebar';
import DashboardPage from './pages/DashboardPage';
import SettingsPage from './pages/SettingsPage';
import AanwezighedenPage from './pages/Aanwezigheden';
import LessenroosterPage from './pages/Lessenrooster';
import ChromebooksPage from './pages/Chromebooks';

function App() {
  const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;

  const [open, setOpen] = useState(false);
  const isMobile = useMediaQuery('(max-width: 768px)'); // Detect mobile devices

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    if (isMobile) {
      setOpen(false);  // Close only on mobile devices
    }
  };

  return (
    <Router>
      <Box sx={{ display: 'flex' }}>
        <CssBaseline />
        {/* Navbar */}
        <Navbar handleDrawerOpen={handleDrawerOpen} />

        {/* Sidebar */}
        <Sidebar
          open={open}
          handleDrawerClose={handleDrawerClose}
          variant={isMobile ? 'temporary' : 'permanent'} // Permanent for large screens
        />

        {/* Main Content with Routes */}
        <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
          <Toolbar />
          <Routes>
            <Route path="/dashboard" element={<DashboardPage />} />
            <Route path="/dashboard/subitem1" />
            <Route path="/dashboard/subitem2" />
            <Route path="/settings" element={<SettingsPage />} />
            <Route path="/aanwezigheden" element={<AanwezighedenPage apiBaseUrl={apiBaseUrl} />} />
            <Route path="/lessenrooster" element={<LessenroosterPage apiBaseUrl={apiBaseUrl} />} />
            <Route path="/chromebooks" element={<ChromebooksPage apiBaseUrl={apiBaseUrl} />} />
          </Routes>
        </Box>
      </Box>
    </Router>
  );
}

export default App;
